.menu {
    display: none;
}

@media screen and (max-width: 720px) {
    .menu {
        display: block;
        position: absolute;
        width: 100vw;
        height: 100vh;
        top: 40px;
        left: 0;

        z-index: 30;
        transition-duration: 2s;
    }

    .menu__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 16px 0;

        width: 100%;
        background-color: #FFFFFF;
        z-index: 10;
    }

    .menu__content li {
        margin-bottom: 16px;
    }
}

.alert-enter {
    opacity: 0;
}
.alert-enter-active {
    opacity: 1;
    transition: opacity 300ms;
}
.alert-exit {
    opacity: 1;
}
.alert-exit-active {
    opacity: 0;
    transition: opacity 300ms;
}
