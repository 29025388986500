@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

/* Указываем box sizing */
*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus-visible {
  outline-color: #00B2A9;
}

h1, h2, h3, h4 {
  font-family: 'Rubrik New', sans-serif;
}

ul,
ol {
  padding: 0;
  list-style: none;
}

/* Убираем внешние отступы */
body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Выставляем основные настройки по-умолчанию для body */
body {
  font-family: 'Roboto', sans-serif;
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;

  background-color: #F5F5F5;
}

/* Элементы a, у которых нет класса, сбрасываем до дефолтных стилей */
a:not([class]) {
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
}

a {
  color: inherit;
  text-decoration: none;
}

/* Упрощаем работу с изображениями */
img {
  max-width: 100%;
  display: block;
}

/* Указываем понятную периодичность в потоке данных у article*/
article > * + * {
  margin-top: 1em;
}

/* Наследуем шрифты для инпутов и кнопок */
input,
button,
textarea,
select {
  font: inherit;
}

p {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;

  color: #10002B;
}

main {
  margin-top: 80px;
}

/* Удаляем все анимации и переходы для людей, которые предпочитай их не использовать */
@media (prefers-reduced-motion: reduce) {
  * {
    -webkit-animation-duration: 0.01ms !important;
            animation-duration: 0.01ms !important;
    -webkit-animation-iteration-count: 1 !important;
            animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

.container {
  width: 100%;
  max-width: 1296px;
  margin: 0 auto;
}

.header-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  width: 100%;

  transition: background-color 300ms;
}

.header-container.fixed {
  position: fixed;
  top: 0;
  background-color: #FFFFFF;
}

.header {
  display: flex;
  align-items: center;
  -moz-column-gap: 100px;
       column-gap: 100px;
  padding: 20px 0;
  justify-content: space-between;
}

.header__logoNav {
  display: flex;
  gap: 40px;
  align-items: center;
  flex-direction: row;
}

.face2-logo-container {
  display: flex;
  align-items: center;
}

.logo-text {
  font-family: Rubrik New, sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;
  -moz-column-gap: 8px;
       column-gap: 8px;
}

.capability-list {
  flex-shrink: 0;
}

.control-description__capabilities__info .logo-text {
  font-size: 26px;
  line-height: 34px;
}

.terminal-title {
  font-family: 'Rubrik New', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
}

.nav-list {
  display: flex;
  list-style: none;
  -moz-column-gap: 56px;
       column-gap: 56px;
}

.header__btnBlock {
  display: flex;
}

.nav__link {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  cursor: pointer;

  color: #6A6A6A;
}

.title {
  font-family: 'Rubrik New', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 80px;
  line-height: 88px;

  margin-bottom: 32px;

  color: #10002B;
}

.text-secondary-description {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 32px;

  margin-bottom: 40px;

  color: #6A6A6A;
}

.button_base {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 20px;
  border: none;
  cursor: pointer;

  background: #00B2A9;
  border-radius: 4px;

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #FFFFFF;
}

.button_base:disabled {
  background: #8c8b8b;
}

.button_outlined {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 20px;
  cursor: pointer;

  background-color: transparent;
  border: 1px solid #00B2A9;
  border-radius: 4px;

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #00B2A9;
}

.close-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.mw1024 {
  max-width: 1024px;
}

.mw681 {
  max-width: 681px;
}

.section {
  max-width: 1296px;
  margin: 160px auto 0;
}

.section-white {
  max-width: 1296px;
  margin: 80px auto;
  padding: 80px 0 0;
}

.section-white h2 {
  margin-bottom: 72px;
}

.bg-white {
  background: #FFFFFF;
}

.services__info {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-basis: 100%;
}

.company-partner-logos {
  margin-top: 212px;

  display: flex;
  justify-content: flex-end;
  -moz-column-gap: 56px;
       column-gap: 56px;
}

.orb-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
/*  height: 150%;*/
  pointer-events: none;
  z-index: -1;
  overflow: visible;
}

.footer {
  padding-top: 40px;
}

@media (prefers-contrast: more) {
  .orb-canvas {
    display: none;
  }
}

h2 {
  font-family: 'Rubrik New', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 56px;
  color: #10002B;

  margin-bottom: 48px;
}

.section-logo-text_white {
  display: flex;
  align-items: center;
  -moz-column-gap: 4px;
       column-gap: 4px;

  font-family: 'Rubrik New', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;

  margin-bottom: 24px;

  color: #FFFFFF;
}

.services-section__main {
  background: linear-gradient(180deg, #6A6B6A 0%, #303030 100%);
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 24px;
}

.bg-terminal {
  background-image: url('/pass-terminal-bg-lg.svg');
  background-repeat: no-repeat;

  background-position: top right;
  padding: 32px 40px;
}

.services-title_main_white {
  max-width: 464px;

  font-family: 'Rubrik New', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;

  color: #FFFFFF;

  margin-bottom: 16px;
}

.services-description_main_white {
  max-width: 464px;

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;

  color: #FFFFFF;

  margin-bottom: 24px;
}

.detail-link {
  display: flex;
  align-items: center;
  -moz-column-gap: 8px;
       column-gap: 8px;

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #00B2A9;
}

.benefits-list {
  margin-top: 44px;
  max-width: 888px;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 16px;
  -moz-column-gap: 16px;
       column-gap: 16px;

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #FFFFFF;

  opacity: 0.7;;
}

.benefits-list__item {
  max-width: 192px  ;
}

.services-section__card {
  height: 100%;
  padding: 32px 24px;
  border-radius: 12px;
  background-color: #FFFFFF;

  display: flex;
  flex-direction: column;
}

.services-section__card .detail-link {
  margin-top: auto;
}

.services-logo-text {
  display: flex;
  align-items: center;
  -moz-column-gap: 4px;
       column-gap: 4px;

  font-family: 'Rubrik New', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 32px;

  color: #10002B;

  margin-bottom: 24px;
}

.services__title {
  font-family: 'Rubrik New', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;

  color: #10002B;

  margin-bottom: 16px;
}

.services__description {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #6A6A6A;

  margin-bottom: 48px;
}

.services {
  grid-gap: 24px;
  gap: 24px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

/*.services > li:first-child {*/
/*  grid-column-start: 1;*/
/*  grid-column-end: 4;*/
/*}*/

.error-text {
  position: absolute;
  margin-top: 4px;

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;

  color: #EE3D32;
}

.body-text {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;

  color: #10002B;
}

.body-text_gray {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;

  color: #6A6A6A;
}

.body-text_white {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;

  color: #FFFFFF;
}

.completed-project__description p {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;

  color: #FFFFFF;
}

.mt40 {
  margin-top: 40px;
}

.control-description {
  display: flex;
  -moz-column-gap: 24px;
       column-gap: 24px;
  align-items: center;
}

.control-description__about {
  flex: 3.2 1;
}

.control-description__capabilities {
  flex: 4 1;
  background: #FFFFFF;
  border-radius: 12px;

  padding: 32px 40px;
}

.control-description__capabilities__info {
  display: flex;
  -moz-column-gap: 28px;
       column-gap: 28px;
  justify-content: space-between;
  align-items: center;
}

.arrow-img-container {
  position: relative;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
}

.about-us {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 24px;
  gap: 24px;

  margin-bottom: 32px;
}

.about-us__smi {
  display: flex;
  -moz-column-gap: 16px;
       column-gap: 16px;

  margin-bottom: 8px;
}

.about-us__smi img {
  width: 32px;
  height: 32px;
}

.block-center {
  margin: 0 auto;
}

.terminal-list {
  display: grid;
  grid-column-gap: 24px;
  -moz-column-gap: 24px;
       column-gap: 24px;
  grid-template-columns: 2fr 1fr 1fr;
  grid-auto-rows: 349px;

  margin-bottom: 80px;
}

.terminal-list__item {
  border-radius: 12px;
  background-image: linear-gradient(180deg, #6A6B6A 0%, #303030 100%);
}

.terminal-item__container {
  height: 100%;
  padding: 24px 32px;
}

.terminal-list__item:first-child .body-text_white {
  max-width: 48%;
}

.terminal-list__item:not(:first-child) > div {
  padding: 24px 16px;
}

.terminal-list__item:nth-child(1) .terminal-item__container {
  background: url('/innoface.svg') no-repeat bottom right;
}

.terminal-list__item:nth-child(2) .terminal-item__container {
  background: url('/innoface-13.svg') no-repeat bottom right;
}

.terminal-list__item:nth-child(3) .terminal-item__container {
  background: url('/innoface-park-1.png') no-repeat bottom right;
  background-size: 80%;
}

.bg-gray {
  background-color: #E8E8E8;
}

.terminal-list__title {
  font-family: 'Rubrik New', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  white-space: pre-wrap;

  color: #FFFFFF;

  margin-bottom: 8px;
}

.terminal-list-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.terminal-list-info p {
  max-width: 314px;
  margin-left: 40px;
}

.terminal-list-docs {
  display: flex;
  -moz-column-gap: 24px;
       column-gap: 24px;
}

.partners-opportunities {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 24px;
  -moz-column-gap: 24px;
       column-gap: 24px;
}

.logo-img-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 40px;
  height: 40px;
}

.partners-opportunities__title {
  margin-top: 26px;
  margin-bottom: 4px;
}

.doc-title {
  margin-bottom: 24px;
}

.doc-link-list {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.doc-link {
  display: flex;
  align-items: center;
  -moz-column-gap: 8px;
       column-gap: 8px;
}

.doc-link p {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #00B2A9;
}

.doc-link__icon {
  position: relative;
  width: 16px;
  height: 16px;

  display: flex;
  align-items: center;
}

.service_notice {
  max-width: 812px;
  margin: 120px auto 0;
  text-align: center;
}

.acquisition-options {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 64px;
  -moz-column-gap: 64px;
       column-gap: 64px;
  grid-row-gap: 40px;
  row-gap: 40px;
}

.acquisition-options__card {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;

  padding: 62px 40px;
  background: url('/acquisition-options-card-bg.png') no-repeat bottom right, #FFFFFF;
  -webkit-backface-visibility: visible;
          backface-visibility: visible;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
}

.acquisition-options__card .services__title {
  max-width: 411px;
}

.sphere-list {
  /*list-style-type: disc;*/
  /*list-style: inside;*/
}

.sphere-list li {
  display: flex;
  -moz-column-gap: 8px;
       column-gap: 8px;
}

.sphere-list li:before {
  content:" · ";
  font-size:32px;
  vertical-align:middle;
  line-height:20px;
}

.application-scope__item {
  display: flex;
  align-items: flex-start;
  -moz-column-gap: 16px;
       column-gap: 16px;
}

.application-scope__title {
  margin-bottom: 8px;
}

.application-scope {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 32px;
  row-gap: 32px;
  grid-column-gap: 24px;
  -moz-column-gap: 24px;
       column-gap: 24px;
}

.section-white-end {
  max-width: 1296px;
  margin: 160px auto 80px;
  padding-bottom: 80px;
}

.completed-project-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: -webkit-max-content;
  grid-template-rows: max-content;
  grid-gap: 24px;
  gap: 24px;
}

.completed-project-list-container {
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  margin-bottom: 64px;
}

.section-after-white {
  max-width: 1296px;
  margin: 80px auto 160px;
}

.completed-project__item {
  position: relative;
  background: #FFFFFF;
  border-radius: 12px;
  overflow: hidden;
  line-height: 0;
}

.completed-project__item span{
  height: 100% !important;
}

.completed-project__item img{
  -o-object-fit: cover;
     object-fit: cover;
}

.completed-project__description {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;

  padding: 8px 16px;
  background: rgba(0, 0, 0, 0.5);
}

.main-video-container {
  position: relative;

  width: 100%;
  height: auto;

  max-width: 850px;
  min-height: 478px;
  max-height: 478px;
  margin-right: auto;
  margin-left: auto;
}

.main-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-width: 0;
  outline-width: 0;
}

.completed-project__item-text {
  display: flex;
  justify-content: center;
  flex-direction: column;

  row-gap: 16px;
}

.description-form-text {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #6A6A6A;
}

.form-contacts {
  margin-bottom: 80px;
}

.form-contacts-input-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 24px;
  -moz-column-gap: 24px;
       column-gap: 24px;
}

.text-center {
  text-align: center;
}

section .mb48 {
  margin-bottom: 48px;
}

.text-input-container {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
}

.text-input {
  width: 100%;

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #6A6A6A;
  outline-color: #00B2A9;

  padding: 16px 20px;
  background: #F5F5F5;
  border: 1px solid #D0D0D0;
  border-radius: 4px;
}

.text-input-error {
  border-color: #EE3D32;
}

.textarea {
  height: 100%;
  width: 100%;
  padding: 18px 24px;

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;

  color: #6A6A6A;
  outline-color: #00B2A9;

  background: #F5F5F5;
  border: 1px solid #D0D0D0;
  border-radius: 4px;
  margin: 0;
}

.description-form-text-wrapper {
  margin-top: 56px;
  margin-bottom: 24px;
}

.footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 82px;
}

.footer-nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  -moz-column-gap: 8px;
       column-gap: 8px;

  margin-bottom: 24px;
}

.footer .nav__link {
  margin-top: 24px;
  color: #10002B;
}

.contact-icon-text {
  display: flex;
  align-items: center;
  -moz-column-gap: 16px;
       column-gap: 16px;
}

.contacts {
  margin-bottom: 16px;
}

.contacts__email-list {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.nav-side {
  flex-grow: 1;
  margin-right: 10%;
}

.control-description__capabilities__info div p {
  margin-bottom: 8px;
}

.control-description__capabilities__info div li {
  margin-bottom: 2px;
}

.about-us__img-container {
  position: relative;
  width: 32px;
  height: 32px;
}

.about-us__img-container img {
  width: 100%;
  height: 100%;
}

.about-us__item {
  display: grid;
  grid-template-columns: -webkit-min-content auto;
  grid-template-columns: min-content auto;
  grid-template-rows: -webkit-min-content auto;
  grid-template-rows: min-content auto;
  grid-column-gap: 16px;
  grid-row-gap: 8px;
}

.about-us__title-container {
  display: flex;
  -moz-column-gap: 16px;
       column-gap: 16px;
}

.about-us__item .link-container {
  align-self: flex-end;
}

.control-description__man-img-container {
  position: relative;
  width: 86px;
  height: 86px;
  display: flex;
  align-items: center;
}

.about-us__item p {
  grid-row-start: 2;
  grid-column-start: 1;
  grid-column-end: 4;
  align-self: flex-start;
}

h2.contacts-title {
  text-align: center;
  margin-bottom: 48px;
}

.section-white:last-child {
  margin-bottom: 40px;
}

.footer .nav__link.company-name {
  color: #6A6A6A;
}

.partners-opportunities__img-container {
  position: relative;
  width: 48px;
  height: 48px;
  flex-shrink: 0;
}

.contact-icon-text p {
  font-weight: 400;
}

.services-section__card .services__title {
  text-align: left;
}

.contacts-block .company-name {
  display: none;
}

.contacts__icon-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.documentation__list {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin: 0 -12px;
}

.documentation__item {
  display: flex;
  width: 33.3333%;
  padding: 12px;
  min-width: 195px;
}

.documentation__content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 18px;
  border: 1px solid #E8E8E8;
  border-radius: 12px;
  cursor: pointer;
  transition: transform .3s ease-in-out, border-color .3s ease-in-out;
}

.documentation__content:hover {
  transform: translateY(-12px);
  border-color: #00B2A9;
}

.documentation__format {
  display: flex;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  padding: 4px 12px;
  border-radius: 39px;
  color: white;
}

.documentation__pdf{
  background: #FF0000;
}

.documentation__doc{
  background: #1951A8;
}

.documentation__docx{
  background: #1951A8;
}

.documentation__zip{
  background: #6A6A6A;
}

.documentation__title {
  margin-top: 18px;
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  margin-bottom: 24px;
}

.documentation__footer {
  display: flex;
  margin-top: auto;
  justify-content: space-between;
}

.documentation__size {
  font-size: 18px;
  font-weight: 400;
  color: #6A6A6A;
}

.documentation__button {
  color: #00B2A9;
}

.documentation__button:hover {
  color: #076F69;
}


@media screen and (max-width: 1400px) {
  .services-section__card {
    padding: 24px;
  }

  .control-description__capabilities__info .body-text_gray {
    font-size: 16px;
  }

  .mw1024 {
    max-width: 78%;
  }
  .container, .section, .section-white, .section-white-end, .section-after-white {
    max-width: 81%;
  }

  .services__description {
    margin-bottom: 8px
  }

  .services-section__card .services__title {
    margin-bottom: 12px;
  }

  .control-description__about .body-text,
  .partners-opportunities .body-text,
  .acquisition-options .body-text {
    font-size: 16px;
    line-height: 24px;
  }

  .acquisition-options {
    grid-column-gap: 24px;
    row-gap: 52px;
  }

  .acquisition-options__card {
    padding: 40px 24px;
    background-position-x: 115%;
    background-position-y: 30px;
  }

  .acquisition-options__card .services__title {
    max-width: 79%;
  }

  .control-description__capabilities__info {
    -moz-column-gap: 18px;
         column-gap: 18px;
  }

  .terminal-list__item:nth-child(3) .terminal-item__container {
    background: url('/innoface-park-1-md.svg') no-repeat bottom right;
  }

  .services-section__card .services__title {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
  }

  .section-after-white {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .control-description__capabilities {
    padding: 32px 16px;
  }
}

@media screen and (max-width: 1270px){
  .nav-list {
    -moz-column-gap: 40px;
         column-gap: 40px;
  }
}

@media screen and (max-width: 1200px) {
  .header__logoNav {
    gap: 30px;
  }

  .nav-list {
    -moz-column-gap: 30px;
         column-gap: 30px;
  }

  .control-description__capabilities .services__title {
    font-size: 21px;
    line-height: 28px;
  }

  .main-video-container {
    min-height: 42vw;
  }

  .completed-project__item-text .body-text {
    font-size: 16px;
    line-height: 24px;
  }

  .control-description__capabilities__info .body-text_gray {
    font-size: 14px;
  }

  .purchase-options {
    text-align: center;
  }

  .control-description__capabilities__info div p {
    font-size: 14px;
    line-height: 20px;
  }

  .body-text_gray {
    font-size: 14px;
    line-height: 20px;
  }

  .title {
    font-size: 48px;
    line-height: 60px;
  }

  .section {
    margin: 120px auto 0;
  }

  h2 {
    margin-bottom: 32px;
  }

  .services-description_main_white {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
  }

  .benefits-list {
    max-width: 75%;
  }

  .benefits-list__item {
    font-size: 14px;
    line-height: 20px;
  }

  .bg-terminal {
    background-position-x: 116%;
  }

  .services-logo-text {
    margin-bottom: 16px;
  }

  .services__description {
    margin-bottom: 16px;
  }

  .control-description__about {
    flex: 2.4 1;
  }

  .about-us {
    grid-template-columns: repeat(2, 1fr);
  }

  .about-us__img-container {
    position: relative;
    width: 24px;
    height: 24px;
  }

  .about-us__img-container img {
    width: 100%;
    height: 100%;
  }

  .about-us__item {
    align-items: flex-start;
    grid-row-gap: 0;
  }

  .about-us__item p {
    grid-row-start: 2;
    grid-column-start: 2;
    grid-column-end: 3;
  }

  .terminal-list__title {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;

    margin-bottom: 4px;
  }

  .terminal-list {
    grid-auto-rows: 255px;
    margin-bottom: 60px;
  }

  .terminal-list__item:nth-child(1) .terminal-item__container {
    background-size: contain;
  }

  .terminal-list__item:nth-child(2) .terminal-item__container {
    background-size: 80%;
    background-position-y: 140%;
  }

  .terminal-list__item:nth-child(3) .terminal-item__container {
    background-size: 60%;
  }

  .body-text_white {
    font-size: 16px;
    line-height: 24px;
  }

  .terminal-list-info p {
    margin-left: 48px;
  }

  .body-text {
    font-size: 16px;
    line-height: 24px;
  }

  .terminal-list-info p {
    max-width: 272px;
  }

  .section-white {
    margin: 60px auto;
    padding: 60px 0 0;
  }

  .partners-opportunities__title {
    margin-top: 8px;
  }

  .partners-opportunities {
    grid-template-columns: repeat(3, 1fr);
    row-gap: 32px;
  }

  .partners-opportunities li:last-child {
    grid-column: 1 / 4;
  }

  .doc-title {
    margin-bottom: 8px;
  }

  .doc-link-list {
    flex-direction: row;
    gap: 12px;
    justify-content: space-between;
  }

  .service_notice {
    margin-top: 60px;
  }

  .acquisition-options__card .services__title {
    margin-bottom: 24px;
    max-width: 65%;
  }

  .acquisition-options {
    grid-column-gap: 24px;
    grid-row-gap: 32px;
  }

  .acquisition-options__card {
    grid-column: 1 / 3;
    grid-row: 2 / 3;
    background: url('/acquisition-options-card-bg-md.png') no-repeat bottom right, #FFFFFF;

    background-position-x: 90%;
    background-size: contain;
  }

  .section-white-end {
    margin-top: 120px;
    margin-bottom: 60px;
  }

  .application-scope__title {
    font-size: 16px;
    line-height: 24px;
  }

  .application-scope__img-container {
    position: relative;
    width: 32px;
    height: 32px;

    flex-shrink: 0;
  }

  .container, .section, .section-white, .section-white-end, .section-after-white {
    max-width: 80%;
  }

  .completed-project__description p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .completed-project-list-container {
    margin-bottom: 32px;
  }

  h2.contacts-title {
    margin-bottom: 32px;
  }

  .description-form-text-wrapper {
    margin-top: 48px;
  }

  .section-white:last-child {
    margin-bottom: 24px;
  }

  .footer .nav__link {
    font-size: 16px;
    line-height: 24px;
  }

  .contacts {
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 24px;
  }

  .contacts__email-list {
    row-gap: 8px;
  }

  .footer-nav {
    margin-bottom: 16px;
  }

  .footer .nav__link {
    margin-top: 16px;
  }

  .header {
    -moz-column-gap: 41px;
         column-gap: 41px;
  }

  .company-partner-logos {
    margin-top: 150px;
  }

  .section-white h2 {
    margin-bottom: 32px;
  }

  .footer {
    padding-top: 24px;
  }
}

@media screen and (max-width: 1070px){
  .nav-list {
    -moz-column-gap: 20px;
         column-gap: 20px;
  }

  .header .button_base {
    padding: 14px 18px;
  }

  .header__logoNav {
    gap: 15px;
  }
}

/*@media screen and (max-width: 780px) {*/
@media screen and (max-width: 940px) {
  .services-logo-text {
    margin-bottom: 0;
  }

  main {
    margin-top: 56px;
  }

  .header {
    -moz-column-gap: 26px;
         column-gap: 26px;
    padding: 12px 0;
  }

  .logo-text {
    font-size: 22px;
    line-height: 28px;
  }

  .face2-logo-container {
    display: flex;
    align-items: center;
    position: relative;
    width: 32px;
    height: 32px;
  }

  .nav__link {
    font-size: 16px;
    line-height: 24px;
  }

  .section:first-child {
    margin-top: 32px;
  }

  .section:nth-child(2) {
    margin-top: 84px;
  }

  .title {
    font-family: 'Rubrik New', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 56px;

    margin-bottom: 16px;
  }

  .text-secondary-description {
    max-width: 73%;
    font-size: 18px;
    line-height: 28px;

    margin-bottom: 24px;
  }

  .button_base {
    font-size: 14px;
    line-height: 20px;

    padding: 14px 16px;
  }

  .company-partner-logos {
    margin-top: 64px;
    -moz-column-gap: 32px;
         column-gap: 32px;
  }

  h2 {
    font-size: 32px;
    line-height: 40px;
  }

  .section-logo-text_white {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;

    margin-bottom: 16px;
  }

  .services__section-logo-container {
    position: relative;
    width: 24px;
    height: 24px;
  }

  .services-title_main_white {
    max-width: 58%;
    font-size: 24px;
    line-height: 32px;

    margin-bottom: 8px;
  }

  .services-description_main_white {
    margin-bottom: 8px;
  }

  .bg-terminal {
    background-position-x: right;
    background-size: contain;
    padding: 16px 24px;
  }

  .benefits-list {
    max-width: -webkit-max-content;
    max-width: -moz-max-content;
    max-width: max-content;
    margin-top: 32px;
  }

  .container, .section, .section-white, .section-white-end, .section-after-white {
    max-width: 89.3%;
  }

  .services {
    gap: 16px;
    grid-template-columns: repeat(1, 1fr);
  }

  .services > li:first-child {
    grid-row: auto/auto;
    grid-column: auto/auto;
  }

  .services-section__card {
    display: grid;
    grid-template-columns: minmax(0, 140px) 1fr;
    align-items: flex-start;

    grid-gap: 32px;

    gap: 32px;
    padding: 16px 24px;
  }

  .services__img-container {
    position: relative;
    width: 24px;
    height: 24px;
  }

  .services-section__card .services__title {
    margin-bottom: 0;
  }

  .services__description {
    margin-bottom: 8px;
  }

  .control-description {
    flex-direction: column;
  }

  .mt40 {
    margin-top: 16px;
  }

  .control-description__capabilities {
    margin-top: 24px;
    padding: 32px;
  }

  .control-description__man-img-container {
    position: relative;
    width: 86px;
    height: 86px;
  }

  .logo-img-container {
    width: 32px;
    height: 32px;
  }

  .control-description__capabilities__info .logo-text {
    font-size: 24px;
  }

  .terminal-list__item .terminal-item__container {
    padding: 16px;
  }

  .body-text_white {
    font-size: 14px;
    line-height: 20px;
  }

  .terminal-list__item:nth-child(1) .terminal-item__container {
    background-position-x: 150%;
  }

  .terminal-list__item:nth-child(2) .terminal-item__container {
    background-position-y: 120%;
    background-position-x: 50px;
    background-size: contain;
  }

  .terminal-list__item:nth-child(3) .terminal-item__container {
    background-position-y: 100%;
    background-position-x: 100%;
    background-size: 85%;
  }

  .terminal-list__item:not(:first-child) .terminal-item__container {
    padding: 16px;
  }

  .terminal-list__item:not(:first-child) .terminal-list__title {
    font-size: 16px;
    line-height: 24px;
  }

  .terminal-list-info p {
    margin-left: 0;
  }

  .terminal-list-info {
    display: grid;
    grid-column-gap: 24px;
  }

  .partners-opportunities {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 24px;
  }

  .partners-opportunities li {
    grid-column: 1/3;
  }

  .partners-opportunities li {
    display: flex;
    gap: 16px;
  }

  .doc-link-list {
    flex-direction: column;
    gap: 16px
  }

  .partners-opportunities__img-container {
    width: 36px;
    height: 36px;
  }

  .partners-opportunities .body-text {
    font-size: 14px;
    line-height: 20px;
  }

  .service_notice {
    max-width: 85%;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
  }

  .bg-white .section {
    margin-top: 120px;
  }

  .acquisition-options .services__title {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;

    margin-bottom: 4px;
  }

  .acquisition-options .body-text {
    font-size: 14px;
    line-height: 20px;

    color: #6A6A6A;
  }

  .acquisition-options__card {
    padding: 24px;
  }

  .acquisition-options__card .services__title {
    margin-bottom: 16px;
  }

  .section-white-end {
    padding-bottom: 60px;
  }

  .completed-project-list-container {
    margin-bottom: 24px;
  }

  .completed-project__description p {
    font-size: 12px;
    line-height: 16px;
  }

  .completed-project__item-text .body-text {
    font-size: 12px;
    line-height: 16px;
  }

  .button_outlined {
    font-size: 13px;
    line-height: 16px;

    padding: 12px;
  }

  .text-input {
    font-size: 14px;
    line-height: 20px;

    padding: 14px 16px;
  }

  .description-form-text-wrapper {
    margin-top: 40px;
    margin-bottom: 16px;
  }

  .description-form-text{
    font-size: 12px;
    line-height: 16px;
  }

  .form-contacts {
    margin-bottom: 60px;
  }

  .footer-nav span {
    display: none;
  }

  .contacts, .contact-icon-text p {
    font-size: 14px;
    line-height: 20px;
  }

  .contact-icon-text p {
    -moz-column-gap: 8px;
         column-gap: 8px;
  }

  .contacts__icon-container {
    width: 16px;
    height: 16px;
  }

  .footer .nav__link {
    font-size: 14px;
    line-height: 20px;
  }

  .footer {
    padding-bottom: 24px;
  }
}
/*@media screen and (max-width: 600px) {*/
@media screen and (max-width: 720px) {
  .desktop-header-nav {
    display: none;
  }

  .header__btnBlock {
    display: none;
  }

  .burger-btn {
    width: 16px;
    height: 16px;
    position: relative;

    cursor: pointer;
  }

  .burger-btn:before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 2px;
    background-color: #10002B;
  }

  .burger-btn span {
    position: absolute;
    top: 7px;
    width: 80%;
    height: 2px;
    background-color: #10002B;
  }

  .burger-btn:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: #10002B;
  }

  .header {
    justify-content: space-between;
  }

  .header-mobile {
    display: flex;
    justify-content: space-between;
    max-width: 89%;
    margin: 0 auto;
  }

  .header {
    padding: 8px 0;
  }

  .documentation__item {
    width: 50%;
  }

  .terminal-list-docs {
    flex-direction: column;
    row-gap: 15px;
  }


  main {
    margin-top: 44px;
  }

  .face2-logo-container {
    width: 24px;
    height: 24px;
  }

  .section:first-child {
    margin-top: 48px;
  }

  .title {
    font-size: 32px;
    line-height: 40px;
  }

  .text-secondary-description {
    max-width: none;
    margin-bottom: 16px;
  }

  .button_base {
    font-size: 13px;
    line-height: 16px;
    padding: 12px;
  }

  .company-partner-logos {
    margin-top: 112px;
  }

  .section:nth-child(2) {
    margin-top: 64px;
  }

  h2 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 24px;
  }

  .bg-terminal {
    padding: 24px;
  }

  .section-logo-text_white {
    font-size: 16px;
    line-height: 20px;
  }

  .services-title_main_white {
    max-width: none;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
  }

  .services-description_main_white {
    max-width: 80%;
    font-size: 14px;
    line-height: 20px;
  }

  .benefits-list {
    margin-top: 48px;
  }

  .benefits-list__item {
    font-size: 12px;
    line-height: 16px;
  }

  .services-section__card {
    padding: 16px;
  }

  .services-section__card .services__title {
    font-size: 16px;
    line-height: 24px;
  }

  .services__description {
    font-size: 14px;
    line-height: 20px;
  }

  .section {
    margin: 80px auto 0;
  }

  .control-description__about .body-text, .partners-opportunities .body-text, .acquisition-options .body-text {
    font-size: 14px;
    line-height: 20px;
  }

  .control-description__capabilities {
    margin-top: 32px;
    padding: 16px;
  }

  .services__title {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;

    margin-bottom: 8px;
  }

  .control-description__capabilities__info {
    -moz-column-gap: 12px;
         column-gap: 12px;
  }

  .about-us__item p {
    font-size: 16px;
    line-height: 24px;
  }

  .terminal-list {
    grid-auto-rows: 160px 192px;
    grid-template-columns: 1fr 1fr;
    gap: 24px;

    margin-bottom: 40px;
  }

  .terminal-list__item:nth-child(1) {
    grid-row: 1/2;
    grid-column: 1/3;
  }

  .terminal-list__item:nth-child(1) .terminal-item__container {
    background-position-x: 90%;
  }

  .terminal-list__item:nth-child(2) .terminal-item__container {
    background: url('/innoface-13-sm.png') no-repeat bottom right;
    background-size: 75%;
  }

  .terminal-list__item:first-child .body-text_white {
    max-width: 60%;
  }

  .terminal-list__item:nth-child(3) .terminal-item__container {
    background-position-y: 100%;
    background-position-x: 90%;
    background-size: 55%;
  }

  .terminal-list-info p {
    font-size: 14px;
    line-height: 20px;
  }

  .section-white {
    margin: 40px auto;
    padding: 40px 0 0;
  }

  .section-white h2 {
    margin-bottom: 24px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
  }

  .partners-opportunities__title {
    margin-top: 0;
    margin-bottom: 0;
  }

  .partners-opportunities {
    grid-template-columns: repeat(7, 1fr);
  }

  .partners-opportunities li {
    grid-column: 1/5;
  }

  .partners-opportunities li:last-child {
    grid-row: 1/3;
    grid-column: 5/9;
  }

  .doc-link__icon {
    display: none;
  }

  .doc-link p {
    font-size: 14px;
    line-height: 20px;
  }

  .service_notice {
    max-width: 100%;

    margin-bottom: 80px;
  }

  .acquisition-options .services__title {
    font-size: 16px;
    line-height: 24px;
    text-align: left;
  }

  .acquisition-options {
    grid-row-gap: 16px;
  }

  .acquisition-options__card {
    padding: 16px 24px;
  }

  .section-white-end {
    margin-top: 80px;
    padding-bottom: 40px;
    margin-bottom: 40px;
  }

  .application-scope__title {
    margin-bottom: 4px;
  }

  .application-scope {
    grid-template-columns: auto;
    grid-row-gap: 16px;
  }

  .section-after-white {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .completed-project-list {
    grid-template-columns: auto;
    grid-row-gap: 16px;
  }

  .completed-project__description p {
    max-width: 43%;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
  }

  .completed-project__item-text {
    justify-content: flex-start;
  }

  .form-contacts-input-container {
    grid-template-columns: auto;
    grid-row-gap: 32px;
  }

  .text-input-container {
    row-gap: 32px;
  }

  .textarea {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding: 14px 16px;
    min-height: 120px;
  }

  .description-form-text-wrapper {
    margin-top: 32px;
    margin-bottom: 24px;
  }

  .form-contacts {
    margin-bottom: 40px;
  }

  .contacts__icon-container {
    display: none;
  }

  .container, .section, .section-white, .section-white-end, .section-after-white {
    max-width: 86%;
  }

  .mw1024 {
    max-width: none;
  }

  .text-secondary-description {
    margin-bottom: 96px;
  }

  .button_base {
    width: 100%;
  }

  .company-partner-logos {
    margin-top: 56px;
  }

  .section:nth-child(2) {
    margin-top: 42px;
  }

  .bg-terminal {
    padding: 16px 24px;
  }

  .section-logo-text_white {
    margin-bottom: 8px;
  }

  .services-description_main_white {
    margin-bottom: 16px;
  }

  .benefits-list {
    margin-top: 24px;

    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 16px;
  }

  .services__title {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }

  .control-description__man-img-container {
    width: 60px;
    height: 60px;
  }

  .logo-img-container {
    width: 16px;
    height: 16px;
  }

  .control-description__capabilities__info .logo-text {
    font-size: 14px;
    -moz-column-gap: 4px;
         column-gap: 4px;
  }

  .control-description__capabilities__info {
    -moz-column-gap: 8px;
         column-gap: 8px;
  }

  .services-section__card {
    padding: 16px 24px;
    grid-template-columns: auto;
    grid-row-gap: 8px;
  }
}

@media screen and (max-width: 516px) {
  .control-description__capabilities__info .body-text_gray {
    font-size: 12px;
  }

  .documentation__item {
    width: 100%;
  }

  .documentation__list {
    margin: 0;
  }

  .footer {
    display: flex;
    flex-direction: column;
  }

  .container, .section, .section-white, .section-white-end, .section-after-white {
    max-width: 87%;
  }

  .section-sm {
    width: 100%;
    max-width: none;
    padding-left: 8px;
    padding-right: 8px;
    margin-left: auto;
    margin-right: auto;
  }

  .section-sm__inner-wrapper {
    padding-left: 24px;
    padding-right: 24px;
  }

  .control-description__capabilities {
    margin-top: 24px;
    padding: 16px 24px;
  }

  .control-description__capabilities__info div p {
    font-size: 12px;
    line-height: 16px;
  }

  .body-text_gray {
    font-size: 12px;
    line-height: 16px;
  }

  .arrow-img-container {
    width: 12px;
    height: 12px;
  }

  .control-description__man-img-container {
    width: 32px;
    height: 32px;
  }

  .control-description__capabilities__info .logo-text {
    font-size: 11px;
  }

  .about-us {
    grid-template-columns: 1fr;
    grid-row-gap: 24px;
    margin-bottom: 24px;
  }

  .about-us__item {
    grid-column-gap: 8px;
  }

  .terminal-list .terminal-list__item .terminal-item__container {
    padding: 16px 24px;
  }

  .terminal-list__item .body-text_white {
    max-width: 50%;
  }

  .terminal-list {
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(140px, auto);
    gap: 16px
  }

  .terminal-list__item:nth-child(1) {
    grid-row: unset;
    grid-column: unset;
  }

  .terminal-list__item:nth-child(2) .terminal-item__container {
    background-size: 50%;
  }

  .terminal-list__item:nth-child(3) .terminal-item__container {
    background-size: 38%;
    background-position-x: 95%;
  }

  .terminal-list-info {
    grid-template-columns: 1fr;
    row-gap: 16px;
  }

  .terminal-list-info>.body-text {
    max-width: none;
    grid-row: 1;
  }

  .partners-opportunities {
    grid-template-columns: 1fr;
    row-gap: 16px;
  }

  .partners-opportunities li, .partners-opportunities li:last-child {
    grid-row: unset;
    grid-column: unset;
  }

  .doc-title {
    margin-top: 16px;
  }

  .service_notice {
    margin-top: 40px;
    text-align: left;
  }

  .acquisition-options {
    grid-template-columns: 1fr;
  }

  .acquisition-options .services__title {
    text-align: left;
    font-size: 14px;
    line-height: 20px;
  }

  .acquisition-options__card {
    grid-row: unset;
    grid-column: unset;

    background-size: contain;
    background-position-x: 120%;
  }

  .acquisition-options__card .button_base {
    width: auto;
  }

  .application-scope__item {
    -moz-column-gap: 8px;
         column-gap: 8px;
  }

  .sphere-list {
    font-size: 14px;
    line-height: 20px;
  }

  .completed-project__description p {
    max-width: 78%;
  }

  .description-form-text-wrapper {
    margin: 24px 0;
  }

  .nav-side {
    margin-right: unset;
  }

  .footer .nav-side .company-name {
    display: none;
  }

  .contacts-block, .contacts__email-list {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contacts-block {
    margin-top: 8px;
  }

  .contacts-block .contacts {
    display: none;
  }

  .contacts-block .company-name {
    display: block;
    margin-top: 24px;
  }
}
.menu {
    display: none;
}

@media screen and (max-width: 720px) {
    .menu {
        display: block;
        position: absolute;
        width: 100vw;
        height: 100vh;
        top: 40px;
        left: 0;

        z-index: 30;
        transition-duration: 2s;
    }

    .menu__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 16px 0;

        width: 100%;
        background-color: #FFFFFF;
        z-index: 10;
    }

    .menu__content li {
        margin-bottom: 16px;
    }
}

.alert-enter {
    opacity: 0;
}
.alert-enter-active {
    opacity: 1;
    transition: opacity 300ms;
}
.alert-exit {
    opacity: 1;
}
.alert-exit-active {
    opacity: 0;
    transition: opacity 300ms;
}

.modalLayout_container__JUQjM {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    max-height: 100vh;
    padding: 16px;
}

.modalLayout_formContainer__ioQtV {
    max-height: 100%;
    overflow-y: auto;

    position: relative;
    background: #FFFFFF;
    border-radius: 24px;
    padding: 32px 40px 24px;
    max-width: 1076px;
    margin: 16px;
}

div.modalLayout_successFormContainer__EtFSH {
    padding: 50px 32px 32px 32px;
}

.modalLayout_formContainer__ioQtV:focus-visible {
    outline: transparent;
}

.modalLayout_formDescription__8ii0d {
    margin-bottom: 32px;
}

.modalLayout_textInputContainer__AZLUg {
    width: 50%;
}

.modalLayout_descBlock__s5dJM {
    width: 50%;
}

.modalLayout_formInputs__4wLp1 {
    display: flex;
    gap: 30px;
}

.modalLayout_close____9jk {
    position: absolute;
    right: 24px;
    top: 24px;
    width: 24px;
    height: 24px;
    cursor: pointer;
}
.modalLayout_close____9jk:hover {
    opacity: 1;
}
.modalLayout_close____9jk:before, .modalLayout_close____9jk:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 24px;
    width: 2px;
    background-color: #6A6A6A;
}
.modalLayout_close____9jk:before {
    transform: rotate(45deg);
}
.modalLayout_close____9jk:after {
    transform: rotate(-45deg);
}

.modalLayout_descriptionFormTextWrapper__iA5T9 {
    margin-top: 40px;
    margin-bottom: 20px;
}

h1.modalLayout_successFormTitle__VlVAM {
    margin-bottom: 24px;
    text-align: center;
}

@media screen and (max-width: 1400px) {
    .modalLayout_formContainer__ioQtV {
        max-width: 681px;
    }
}

@media screen and (max-width: 1200px) {
    .modalLayout_formContainer__ioQtV {
        max-width: 616px;
    }
}

@media screen and (max-width: 780px) {
    .modalLayout_formContainer__ioQtV {
        max-width: 574px;
    }

    .modalLayout_formInputs__4wLp1 {
        gap: 20px;
    }

    .modalLayout_formDescription__8ii0d {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 16px;
    }

    .modalLayout_formSuccessDescription__iOVcc {
        margin-bottom: 24px;
    }

    .modalLayout_textInputContainer__AZLUg {
        row-gap: 32px;
    }

    .modalLayout_descriptionFormTextWrapper__iA5T9 {
        margin-top: 32px;
        margin-bottom: 16px;
    }

    .modalLayout_formTitle__pxtY9, .modalLayout_successFormTitle__VlVAM {
        font-family: 'Rubrik New', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;

        text-align: left;
    }
}

@media screen and (max-width: 600px) {
    .modalLayout_formContainer__ioQtV {
        max-width: 426px;
    }

    .modalLayout_formInputs__4wLp1 {
        flex-direction: column;
    }

    .modalLayout_textInputContainer__AZLUg {
        width: 100%;
        gap: 20px;
    }

    .modalLayout_descBlock__s5dJM {
        width: 100%;
    }

    .modalLayout_descriptionFormTextWrapper__iA5T9 {
        margin-bottom: 24px;
    }

    .modalLayout_formTitle__pxtY9, .modalLayout_successFormTitle__VlVAM {
        font-family: 'Rubrik New', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;

        text-align: left;
    }
}

@media screen and (max-width: 400px) {
    .modalLayout_formContainer__ioQtV {
        max-width: none;
        padding: 32px 16px 24px;
    }

    .modalLayout_formTitle__pxtY9 {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;

        margin-bottom: 8px;
    }

    .modalLayout_successFormTitle__VlVAM {
        margin-bottom: 16px;
    }

    .modalLayout_formDescription__8ii0d {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
    }
}
