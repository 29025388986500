.container {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    max-height: 100vh;
    padding: 16px;
}

.formContainer {
    max-height: 100%;
    overflow-y: auto;

    position: relative;
    background: #FFFFFF;
    border-radius: 24px;
    padding: 32px 40px 24px;
    max-width: 1076px;
    margin: 16px;
}

div.successFormContainer {
    padding: 50px 32px 32px 32px;
}

.formContainer:focus-visible {
    outline: transparent;
}

.formDescription {
    margin-bottom: 32px;
}

.textInputContainer {
    width: 50%;
}

.descBlock {
    width: 50%;
}

.formInputs {
    display: flex;
    gap: 30px;
}

.close {
    position: absolute;
    right: 24px;
    top: 24px;
    width: 24px;
    height: 24px;
    cursor: pointer;
}
.close:hover {
    opacity: 1;
}
.close:before, .close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 24px;
    width: 2px;
    background-color: #6A6A6A;
}
.close:before {
    transform: rotate(45deg);
}
.close:after {
    transform: rotate(-45deg);
}

.descriptionFormTextWrapper {
    margin-top: 40px;
    margin-bottom: 20px;
}

h1.successFormTitle {
    margin-bottom: 24px;
    text-align: center;
}

@media screen and (max-width: 1400px) {
    .formContainer {
        max-width: 681px;
    }
}

@media screen and (max-width: 1200px) {
    .formContainer {
        max-width: 616px;
    }
}

@media screen and (max-width: 780px) {
    .formContainer {
        max-width: 574px;
    }

    .formInputs {
        gap: 20px;
    }

    .formDescription {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 16px;
    }

    .formSuccessDescription {
        margin-bottom: 24px;
    }

    .textInputContainer {
        row-gap: 32px;
    }

    .descriptionFormTextWrapper {
        margin-top: 32px;
        margin-bottom: 16px;
    }

    .formTitle, .successFormTitle {
        font-family: 'Rubrik New', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;

        text-align: left;
    }
}

@media screen and (max-width: 600px) {
    .formContainer {
        max-width: 426px;
    }

    .formInputs {
        flex-direction: column;
    }

    .textInputContainer {
        width: 100%;
        gap: 20px;
    }

    .descBlock {
        width: 100%;
    }

    .descriptionFormTextWrapper {
        margin-bottom: 24px;
    }

    .formTitle, .successFormTitle {
        font-family: 'Rubrik New', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;

        text-align: left;
    }
}

@media screen and (max-width: 400px) {
    .formContainer {
        max-width: none;
        padding: 32px 16px 24px;
    }

    .formTitle {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;

        margin-bottom: 8px;
    }

    .successFormTitle {
        margin-bottom: 16px;
    }

    .formDescription {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
    }
}